//---------------------------------------------------------//
// Override Boostrap Variables
//---------------------------------------------------------//



// Colors

$gray-lighter: 				#f7f7f9;
$gray-light:				#9d9d9d;
$gray:						#666666;
$gray-darker:				#0c0033;


$text-color:            	$gray-darker;

$brand-primary:				#2b2d5b;
$brand-primary-light:		#848098;
$brand-secondary:			#fb053e;

$link-color:            $brand-primary;
$link-hover-color:      $brand-secondary;


// Typography

$font-family-sans-serif:  'Open Sans', sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$line-height-base:        1.57;

$headings-font-family:    'TradeGothic', 'Open Sans';
$headings-line-height:    1;
$headings-font-weight:    bold;
$headings-color:          $brand-primary;





// BUttons

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             darken($btn-primary-bg, 5%);

$btn-secondary-color:              #fff;
$btn-secondary-bg:                 $brand-secondary;
$btn-secondary-border:             darken($btn-secondary-bg, 5%);




// Grid System

$grid-gutter-width:         30px;