//---------------------------------------------------------//
// Icons
//---------------------------------------------------------// 
// - All Icons
//---------------------------------------------------------//

.icon-image, .icon {
	display: inline-block;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-margin-right {
	margin-right: 10px;
}

.icon-image-arrow-right {
	background-image: url('#{$icon-url}/icon-arrow-right.png');
	@include background-retina(13, 23);
}