//---------------------------------------------------------//
// Hero
//---------------------------------------------------------// 


@include breakpoint(lg-only) {
	.hero.home {
		min-height: 635px !important;
	}
}
@media only screen and (min-width: 1300px) {
	.hero.home {
		min-height: 600px !important;
	}
} 

@media only screen and (min-width: 1290px) {
	.hero.home {
		min-height: 589px !important;
	}
} 

@media only screen and (min-width: 1280px) {
	.hero.home {
		min-height: 570px !important;
	}
} 

@media only screen and (min-width: 1100px) {
	.hero.home {
		min-height: 510px !important;
	}
} 

@include breakpoint(sm-only) {
	.hero.home {
		min-height: 506px !important;
	}
}

.title-header {
    margin-top: 100px !important;
}


@media only screen and (min-width: 700px) {
	.title-header {
	    margin-top: 0px !important;
	}

	.body-header {
		 margin-top: 0px !important;
	}
}

.body-header {
	  margin-top: 250px !important;
}

@include breakpoint(md) {
	.title-header {
	    margin-top: 0px !important;
	}
	.body-header {
		 margin-top: 0px !important;
	}
}


@include breakpoint(xs-only) {
	.hero.home {
		min-height: 403px !important;
	}
}

@include breakpoint(md-only) {
	.hero.home {
		min-height: 500px !important;
	}
}
.hero {
	position: relative;    
	min-height: 635px;
	color: #ffffff;
	&:before, &:after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 2;
	}
	&:after {
		background: -moz-linear-gradient(top, rgba(13,0,51,0) 0%, rgba(13,0,51,0.09) 23%, rgba(12,0,52,0.39) 72%, rgba(12,0,52,0.5) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(13,0,51,0) 0%,rgba(13,0,51,0.09) 23%,rgba(12,0,52,0.39) 72%,rgba(12,0,52,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(13,0,51,0) 0%,rgba(13,0,51,0.09) 23%,rgba(12,0,52,0.39) 72%,rgba(12,0,52,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0033', endColorstr='#800c0034',GradientType=0 ); /* IE6-9 */
		height: 368px;
		bottom: 0;
	}
	&.hero-inner {
		min-height: 1px;
		height: 280px;
		.hero-content {
			padding-top: 0;
			padding-bottom: 120px;
		}
	}
	.hero-image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.video-player {
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
        min-width: 100%;
        width: 100%;
		height: 110%;
		.video-placeholder {
			position: absolute;
			top: 0;
			left: 0;
			background-size: cover !important;
			width: 100%;
			height: 100%;
		}
		&.active {
			.video-placeholder {
				display: none;
			}
		}
	}

	.hero-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: table;
		padding-top: 60px;
		padding-bottom: 60px;
		z-index: 3;
		.hero-content-inner {
			display: table-cell;
			vertical-align: middle;
			.container-fluid {
				padding-left: 50px !important;
			}
		}
		.hero-title-content {
			text-align: center;
			margin-top: 140px;
		}
	}

	h1, .h1, h2, .h2 {
		text-transform: uppercase;
		font-weight: bold;
		line-height: 1;
		letter-spacing: -1px;
		color: inherit;
		small {
			color: inherit;
			font-size: 36px;
			font-weight: bold;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	h2, .h2 {
		font-size: 40px;
	}
	@include breakpoint(xs-only) {
		&.fullscreen {
			height: 550px;
			.h4 {
				font-size: 16px;
			}
		}
		.play-btn {
			position: relative;
			transform: none;
			margin-top: 20px;
			margin-bottom: 20px;
			top: 0;
			left: $grid-gutter-width / 2;
		}
		&.hero-inner {
			.hero-content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: table;
				.hero-content-inner {
					display: table-cell;
					vertical-align: bottom;
				}
			}
		}
	}
	@include breakpoint(sm) {
		h1, .h1 {
			font-size: 50px;
		}
		h2, .h2 {
			font-size: 48px;
		}
		&:before {
			// background-color: rgba(#1a1457, 0.5);
			top: 0;
			height: 130px;
			background: -webkit-gradient(linear, left top, left bottom, from(rgba(13,0,51,0)), color-stop(23%, rgba(13,0,51,0.09)), color-stop(72%, rgba(12,0,52,0.39)), to(rgba(12,0,52,0.5)));
		    background: linear-gradient(to top, rgba(13,0,51,0) 0%, rgba(13,0,51,0.09) 23%, rgba(12, 0, 52, 0.7) 72%, rgb(12, 0, 52) 100%);
		    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0033', endColorstr='#800c0034',GradientType=0 );
		}
		
		&.hero-inner {
			height: 350px;
			&:before {
				height: 273px;
			}
			.hero-content {
				padding-bottom: 130px;
				.hero-content-inner {
					vertical-align: bottom;
				}
				.hero-title-content {
					text-align: left;
				}
			}
		}
		&.hero-inner {
			.container-fluid {
				padding-left: 40px;
			}
		}
	}
	@include breakpoint(md) {
		&:before {
			height: 273px;
		}
		h1, .h1 {
			font-size: 60px;
		}
	
		&.hero-inner {
			.container-fluid {
				padding-left: 100px;
			}
			.hero-content {
				padding-bottom: 145px;
			}
		}
		.hero-content {
			.hero-content-inner {
				.container-fluid {
					padding-left: 100px !important;
				}
			}
		}
	}
	@include breakpoint(lg) {
		h1, .h1 {
			font-size: 72px;
		}
	}
}



