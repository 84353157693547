//---------------------------------------------------------//
// Slider 
//---------------------------------------------------------// 


// Slick Arrow

.slick-arrow {
	position: absolute;
	z-index: 2;
	top: 0;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.slick-prev {
	left: 0;
}
.slick-next {
	right: 0;
}


.slide {
	img {
		max-width: 100%;
	}
}




// Slick Dots

.slick-dots {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		display: block;
		padding-top: 3px;
		padding-bottom: 3px;
	}
	a {
		cursor: pointer;
		width: 10px;
		height: 10px;
		border: 1px solid #444c8b;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		display: block;
		border-radius: 50%;
	}
	.slick-active a, a:hover, a:focus {
		background-color: #444c8b;
	}
}


// Event Slider

.event-slider-container {
	overflow: hidden;
	h2 {
		margin-bottom: 14px;
	}
	@include breakpoint(md) {
		h2 {
			margin-bottom: 15px;
		}
	}
}

.event-slider {
	color: #ffffff;
	position: relative;
	.slick-list {
		overflow: visible;
		padding-left: 40px;
		padding-right: 40px;
	}
	.slide {
		overflow:hidden;
		box-shadow: 1px 0px 8px #12133a;
		width: 240px;
		position: relative;
		margin-top: 24px;
		height: 355px;
		background-color: $brand-primary;
		-webkit-transition: all $easing-speed $easing;
		transition: all $easing-speed $easing;
		color: inherit;
		display: block;
		.arrow {
			position: absolute;
			bottom: 30px;
			right: 20px;
			z-index: 3;
			opacity: 0;
			-webkit-transition: opacity $easing-speed $easing;
			transition: opacity $easing-speed $easing;
		}
		.slide-image {
			opacity: 0.4;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: 50% 0;
			-webkit-transition: all $easing-speed $easing;
			transition: all $easing-speed $easing;
		}
		.slide-content {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 3;
			padding: 30px;
			line-height: 1.2;
			font-weight: bold;
			-webkit-transition: all $easing-speed $easing;
			transition: all $easing-speed $easing;
			h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
				margin-bottom: 12px;
				color: inherit;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&:after {
			content: '';
			background: -moz-linear-gradient(top, rgba(13,0,52,0) 0%, rgba(13,0,52,0.06) 10%, rgba(13,0,52,0.2) 25%, rgba(12,0,52,0.68) 64%, rgba(12,0,52,0.84) 79%, rgba(12,0,52,0.94) 90%, rgba(12,0,52,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(13,0,52,0) 0%,rgba(13,0,52,0.06) 10%,rgba(13,0,52,0.2) 25%,rgba(12,0,52,0.68) 64%,rgba(12,0,52,0.84) 79%,rgba(12,0,52,0.94) 90%,rgba(12,0,52,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(13,0,52,0) 0%,rgba(13,0,52,0.06) 10%,rgba(13,0,52,0.2) 25%,rgba(12,0,52,0.68) 64%,rgba(12,0,52,0.84) 79%,rgba(12,0,52,0.94) 90%,rgba(12,0,52,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0034', endColorstr='#0c0034',GradientType=0 ); /* IE6-9 */
			height: 368px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 2;
			-webkit-transition: opacity $easing-speed $easing;
			transition: opacity $easing-speed $easing;
		}
	}
	.slick-arrow {
		width: 40px;
		height: 100%;
		background-color: rgba($brand-primary, 0.95);
		top: 14px;
		height: 355px;
		&:hover {
			// background-color: darken($brand-primary, 10%);
			background-color: #e8053c;
		}
	}
	.slick-next {
		right: 0px;
		background-image: url('#{$icon-url}/icon-arrow-right.png');
		background-size: 13px 23px;
		
	}
	.slick-prev {
		left: 0px;
		background-image: url('#{$icon-url}/icon-arrow-left.png');
		background-size: 13px 23px;
	}

	@include breakpoint(md) {

		a.slide.slick-slide.slick-current.slick-active.slick-center {
		    height: 600px;
		    width: 515px;
		}
	}

	@include breakpoint(xs-only) {
		.slide {
			&:after {
				opacity: 0;
			}
			.arrow {
				opacity: 1;
			}
			// .slide-image {
			// 	opacity: 1;
			// }
			.h1 {
				font-size: 26px;
			}
		}
		.slick-center {
			.slide-image {
				opacity: 1;
			}
		}
		.slick-center, .slide:hover {
			margin-top: 0;
			margin-bottom: 0;
			height: 390px;
			
			.slide-content {
				background-color: rgba($brand-secondary, 0.9);
			}
		}
	}
	@include breakpoint(sm) {
		.slick-arrow {
			width: 50px;
			height: 455px;
			background-color: $brand-primary;
		}
		.slick-list {
			padding-left: 50px;
			padding-right: 50px;
		}
		.slick-next {
			box-shadow: -14px 0 25px #12133a;
		}
		.slick-prev {
			box-shadow: 14px 0 25px #12133a;
		}
		.slide {
			// width: 290px;
			width: 350px;
			height: 455px;
			margin-bottom: 24px;
			.slide-image {
				top: -65px;
				width: 113%;
				height: 113%;
			}
		}
		.slick-center {
			.slide-image {
				opacity: 1;
			}
		}
		.slick-center, .slide:hover {
			margin-top: 0;
			margin-bottom: 0;
			height: 503px;
			&:after {
				opacity: 0;
			}
			.arrow {
				opacity: 1;
			}
			.slide-content {
				background-color: rgba($brand-secondary, 0.9);
			}
		}
	}
}




.exercise {
	.slick-arrow {
		top: auto;
		left: auto;
		bottom: -30px;
		right: 0;
		width: 60px;
		height: 60px;
		background-color: $brand-secondary;
		&:hover, &:focus {
			background-color: darken($brand-secondary, 10%);
		}
	}
	.slick-next {
		right: 0px;
		background-image: url('#{$icon-url}/icon-arrow-right.png');
		background-size: 13px 23px;
		
	}
	.slick-prev {
		right: 70px;
		background-image: url('#{$icon-url}/icon-arrow-left.png');
		background-size: 13px 23px;
	}
	.slick-dots {
		position: absolute;
		top: 50%;
		right: 40px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	@include breakpoint(sm) {
		.slick-arrow {
			bottom: -50px;
			width: 100px;
			height: 100px;
		}
		.slick-prev {
			right: 109px;
		}
	}
}