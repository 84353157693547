//---------------------------------------------------------//
// Thumbnails
//---------------------------------------------------------// 


.thumbnail {
	position: relative;
	
	@include clearfix();
	.thumbnail-image {
		position: relative;
		
	}

}


.thumbnail-image {
	width: 450px;
	height: 480px;
	position: relative;
	background-color: $brand-primary;
	color: #ffffff;
	.filler {
		background-color: $brand-secondary;
		position: absolute;
		bottom: -140px;
		left: 0;
		width: 100%;
		height: 140px;
		display: none;
	}
	&.thumbnail-image-test {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 245px;
			background-color: rgba($brand-primary, 0.4);
			background: -webkit-gradient(linear, left top, left bottom, from(rgba(13,0,51,0)), color-stop(23%, rgba(13,0,51,0.09)), color-stop(72%, rgba(12,0,52,0.39)), to(rgba(12,0,52,0.5)));
		    background: linear-gradient(to top, rgba(13,0,51,0) 0%, rgba(13,0,51,0.09) 23%, rgba(12, 0, 52, 0.7) 72%, rgb(12, 0, 52) 100%);
		}
		.thumbnail-image-inner {
			opacity: 0.6;
		}
	}
	.top-title {
		position: absolute;
		top: 30px;
		left: 30px;
		z-index: 3;
	}
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
		margin-bottom: 30px;
		color: inherit;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&:after {
		content: '';
		background: -moz-linear-gradient(top, rgba(13,0,52,0) 0%, rgba(13,0,52,0.06) 10%, rgba(13,0,52,0.2) 25%, rgba(12,0,52,0.68) 64%, rgba(12,0,52,0.84) 79%, rgba(12,0,52,0.94) 90%, rgba(12,0,52,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(13,0,52,0) 0%,rgba(13,0,52,0.06) 10%,rgba(13,0,52,0.2) 25%,rgba(12,0,52,0.68) 64%,rgba(12,0,52,0.84) 79%,rgba(12,0,52,0.94) 90%,rgba(12,0,52,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(13,0,52,0) 0%,rgba(13,0,52,0.06) 10%,rgba(13,0,52,0.2) 25%,rgba(12,0,52,0.68) 64%,rgba(12,0,52,0.84) 79%,rgba(12,0,52,0.94) 90%,rgba(12,0,52,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d0034', endColorstr='#0c0034',GradientType=0 ); /* IE6-9 */
		height: 246px;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 2;
		-webkit-transition: opacity $easing-speed $easing;
		transition: opacity $easing-speed $easing;
	}
	.thumbnail-image-inner {
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		opacity: 0.4;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-transition: opacity $easing-speed $easing;
		transition: opacity $easing-speed $easing;
	}
	.thumbnail-image-content {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 3;
		padding: 30px;
		line-height: 1.2;
		font-weight: bold;
		-webkit-transition: all $easing-speed $easing;
		transition: all $easing-speed $easing;
	}
	@include breakpoint(sm) {
		.filler {
			display: block;
		}
	}
	@include breakpoint(md) {
		.top-title {
			top: 50px;
			left: 50px;
		}
		.thumbnail-image-content {
			padding: 50px;
		}
	}
}

