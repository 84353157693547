//---------------------------------------------------------//
// Custom Variables
//---------------------------------------------------------//


$browser-context: 					16;
$globalMarginBottom: 				25px;

$img-url:							'../../assets/images';
$font-url:							'../../assets/fonts';
$icon-url:							'../../assets/images/icons';

$easing-speed: 						0.25s;
$easing: 							ease-in-out;


$navbar-menu-width:					280px;