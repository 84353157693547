//---------------------------------------------------------//
// Buttons
//---------------------------------------------------------// 
// - All Buttons
//---------------------------------------------------------//



button, a, .btn {
	@include transition(background-color $easing-speed $easing, border-color $easing-speed $easing, color $easing-speed $easing);
}

button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
}



.btn {
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 30px;
	padding: 10px 40px;
	box-shadow: 0 0 20px rgba(#000000, 0.3);
	&.btn-secondary {
		background-color: $btn-secondary-bg;
		color: $btn-secondary-color;
		&:hover, &:focus {
			background-color: darken($btn-secondary-bg, 10%);
		}
	}
	@include breakpoint(sm) {
		padding: 16px 30px;
		&.btn-lg {
			font-size: 20px;
			padding: 20px 60px;
			border-radius: 50px;
		}
	}
	@include breakpoint(md) {
		padding: 21px 40px;
		&.btn-lg {
			font-size: 24px;
			padding: 30px 60px;
		}
	}
	@include breakpoint(lg) {
		padding: 21px 50px;
	}
}