//---------------------------------------------------------//
// Panels
//---------------------------------------------------------// 

.panel {
	background-color: #ffffff;
	box-shadow: 0 0 40px rgba(#000000, 0.3);
	min-height: 200px;
	.panel-heading {

	}
	.panel-body {
		padding: 50px 15px;
	}
	@include breakpoint(sm) {
		.panel-body {
			padding: 70px 50px;
		}
	}
}