//---------------------------------------------------------//
// Main
//---------------------------------------------------------// 

.intl-tel-input .country-list .country.highlight {
	width: 100%;
}

.events {
	.event-block {
		margin-bottom: 50px !important;
	}
}
.registration {
	label {
		h4 {
			margin-bottom: 10px !important;
		}
	}
	.form-control {
		padding-left: 0px !important;
		border-bottom: 1px solid #ada5a5 !important;
	    border-top: transparent  !important;
	    border-left: transparent  !important;
	    border-right: transparent  !important;
	    box-shadow: none  !important;
	    border-radius: unset  !important;
	}

	input#phonenum-tb {
		padding-left: 50px !important;
	}
}

.bs-callout-danger {
    border-left-color: #fb053e !important;
}

.bs-callout-success {
	 border-left-color: #71be6b !important;
}

.bs-callout {
    padding: 10px;
    margin-bottom: 20px;
    border-left: 1px solid #eee;
    border-left-width: 3px;
}

.pagination li a, .pagination li span {
    padding: 30px;
    background-color: #2b2d5b;
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.pagination li a:hover {
   background-color: #1b1c38 !important;
	color: #FFF;
}

.pagination>.disabled>span {
    background-color: #f8f8fa !important;
}

.pagination li.active span:hover{ 

	background-color: #ca0331 !important;
   border-color: #ca0331  !important;
}

.pagination li.active span {
    background-color: #fb053e;
    border-color: #fb053e;
}

.main {
	.main-offset-top.home {
		margin-top: -215px;
	}
	
	.main-offset-top {
		margin-top: -215px;
		position: relative;
		z-index: 10;
	}

	.main-offset-top.team-reg {
		margin-top: -100px !important;
	}

	@include breakpoint(md) {
		.main-offset-top.home {
			margin-top: -315px;
		}
		.main-offset-top {
			margin-top: -135px;
		}
	}

	@include breakpoint(sm) {
		.main-offset-top.home {
			margin-top: -315px;
		}
		
	}

	.main-offset-top.events-page  {
		margin-top: -100px !important;
	}

	@include breakpoint(lg) {
		.main-offset-top.events-page {
			margin-top: -100px !important;
		}
		.main-offset-top.team-reg {
			margin-top: -100px !important;
		}
	}

}

.panel-fluid {
	background-color: transparent;
	box-shadow: none;
	padding-top: 95px;
	@include breakpoint(sm) {
		background-color: #ffffff;
		box-shadow: 0 0 40px rgba(0,0,0,0.3);
		padding-top: 0px;
	}
}

#thetest {
	.event-content {
		height: 100%;
		min-height: 750px;
	}
	.event-content-inner {
		max-width: 80%;
		margin: auto;
		@include breakpoint(md){ 
			margin: 0px;
		}
		.event-equipment {
			margin-left: -17px;
		}
		.row {
			margin-top: 80px;
		}
	}
}

// Blocks

.block {
	padding: 40px 0 40px 0;
	@include breakpoint(sm) {
		padding: 50px 0 50px 0;
	}
	@include breakpoint(md) {
		padding: 80px 0 80px 0;
	}
}
.block-content {
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 0;
	}
	@include breakpoint(sm) {
		margin-bottom: 50px;
	}
	@include breakpoint(md) {
		margin-bottom: 80px;
	}
}

.bg-primary {
	background-color: $brand-primary;
	color: #ffffff;
	* {
		color: inherit;
	}
}




// Content Line

.content-line {

	padding-right: 20px;
    padding-left: 20px;
	position: relative;
	&.content-line-sm {
		&:before {
			top: 8px;
			height: 66px;
		}
	}
	&:before {
		content: '';
	    width: 3px;
	    height: 111px;
	    position: absolute;
	    top: 1px;
	    left: 5px;
		background-color: $brand-secondary;
	}
	@include breakpoint(sm) {
		&:before {
			content: '';
			width: 3px;
			height: 165px;
			position: absolute;
			top: 18px;
			left: -33px;
			background-color: $brand-secondary;
		}
	}
	.content-score-info {
		font-size: 16px;
		@include breakpoint(sm) {
			font-size: 20px;
		}
	}
}



// THe test

.the-test {
	overflow: hidden;
}

.test-title {
	font-size: 25px !important;
	@include breakpoint(sm) {
		font-size: 48px !important;
	}
}

.the-test-intro {
	@extend .nav;
	color: $brand-primary;
	font-weight: bold;
	margin-bottom: 35px;
	line-height: 1.3;
	li {
		display: block;
		margin-bottom: 3px;
	}
	font-size: 24px;
	@include breakpoint(sm) {
		font-size: 28px;
		li {
			margin-bottom: 3px;
		}
	}
	@include breakpoint(md) {
		font-size: 40px;
	}
	@include breakpoint(lg) {
		font-size: 48px;
	}
	
	li:hover span {
	    background-color: #fb053e;
    	color: #ffffff;
    	padding: 0px 15px;
    	line-height: 1.2;
    	display: inline-block;
	}
}

.test-list {
	@extend .nav;
	text-align: center;
	font-size: 0;
	display: table;
	table-layout: fixed;
	width: 100%;
	margin-bottom: 50px;
	position: relative;
	z-index: 2;
	li {
		font-size: $font-size-base;
		position: relative;
		padding: 25px 30px;
		position: relative;
		&:nth-child(even) {
			.skew {
				background-color: darken($gray-lighter, 3%);
			}
		}
		.skew {
			background-color: $gray-lighter;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 3px;
			right: 3px;
			z-index: -1;
			-webkit-transform: skew(-20deg);
		    transform: skew(-20deg);
		}
	}
	.num {
		font-size: 70px;
		color: $brand-primary;
		font-weight: bold;
		line-height: 0.7;
		display: block;
		margin-bottom: 18px;
	}
	@include breakpoint(sm) {
		li {
			display: table-cell;
			vertical-align: middle;
		}
	}
	@include breakpoint(md) {
		.num {
			font-size: 100px;
		}
	}
}



// EVENT BLOCK
// .events {
// 	.event-block {
		// &:hover {
		// 	background-color: #ffffff;
		// 	box-shadow: 0 0 40px rgba(#000000, 0.3);
		// 	.thumbnail-image {
		// 		&:after {
		// 			opacity: 0;
		// 		}
		// 	}
		// 	.thumbnail-image-inner {
		// 		opacity: 0.6;
		// 	}
		// 	.thumbnail-image-content {
		// 		background-color: rgba($brand-secondary, 0.9);
		// 	}
		// }
		.current-event {
			background-color: #ffffff;
			box-shadow: 0 0 40px rgba(#000000, 0.3);
			.thumbnail-image {
				&:after {
					opacity: 0;
				}
			}
			.thumbnail-image-inner {
				opacity: 0.6;
			}
			.thumbnail-image-content {
				background-color: rgba($brand-secondary, 0.9);
			}
		}
// 	}
// }


.events {

	.btn {
		padding: 10px 20px;
	    font-size: 12px !important;
	    margin-left: 10px;
	}

	@include breakpoint(md) {
	    #h1 {
			font-size: 40px;
		}
		.btn {
		    font-size: 19px !important;
		    margin-left: unset;
		}
	}
	@include breakpoint(sm) {
		.center {
			text-align: left;
		}
		.btn {
			padding: 10px 40px;
		}
	}
}

.container-fluid {
	padding-left: 24px !important;
	padding-right: 24px !important;

	@include breakpoint(md) {
	    padding-left: 50px !important;
		padding-right: 50px !important;
    }
}

.event-block {

	@include clearfix();
	margin-bottom: 85px;
	background-color: #f8f8fa;
	-webkit-transition: all $easing-speed $easing;
	transition: all $easing-speed $easing;
	position: relative;
	z-index: 2;
	.event-image {
		width: 290px;
		height: 750px;
		position: relative;
		margin-left: 9%;
	}
	.thumbnail-image {
		width: 100%;
		height: 100%;
		position: relative;
		top: 0;
		left: 0;
		padding: 30px 15px 30px 15px;
	}
	.event-content {
		overflow: hidden;
		display: block;
		padding: 30px 15px 30px 15px;
		position: relative;
		
	}
	// .event-content-inner {
	// 	max-width: 600px;
	// }
	&.event-active {
		background-color: #ffffff;
		box-shadow: 0 0 40px rgba(#000000, 0.3);
		.thumbnail-image {
			&:after {
				opacity: 0;
			}
		}
		.thumbnail-image-inner {
			opacity: 0.6;
		}
		.thumbnail-image-content {
			background-color: rgba($brand-secondary, 0.9);
		}

	}
	.workout-description {
		overflow-y: auto;
	    height: 400px;
	}

	.event-row {
		margin-left: 4px !important;
		width: 125%;
		margin-left: -28px !important;

		.label {
			padding: 0;
			display: unset;
			color: #9d9d9d;
			@include breakpoint(sm) {
				display:block;
				padding: .2em .6em .3em;
			}
		}
	}
	.event-workout-name {
		font-size: 30px;
	}
	.row {
		.label {
			text-align: unset;
		}
	}


	@include breakpoint(sm) {
		margin-bottom: 200px;
		.event-image {
			float: left;
			width: 300px;
			height: 350px;
			
		}
		.event-content {
			padding: 30px 30px 30px 30px;
			
		}
		.workout-description {
		    overflow-y: hidden;
			width: auto;
		    height: auto;
		}
		.row {
			width: auto;
			.label {
				text-align: unset;
			}
		}
		.event-content-inner {
			.event-equipment {
				margin: auto;
			}
		}
		.thumbnail-image {
			padding: 30px 15px 30px 15px;
		}
		.event-row {
			margin-left: auto;
		}
		
	}
	
	@include breakpoint(md) {
		.event-content {
			// padding: 40px 50px 40px 50px;
			padding: 40px 50px 0px 50px;
		}
		.event-image {
			width: 350px;
			height: 400px;
			margin-left: auto;
		}
		.event-workout-name {
			font-size: 36px;
		}

		.row {
			.label {
				text-align: center;
			}
		}
		
		
	}
	@include breakpoint(lg) {
		.list {
			&:last-child {
				margin-bottom: 0;
			}
		}
		.event-image {
			width: 450px;
			height: 750px;
		}

		.event-content {
		    height: 750px;
		}

		.event-content-inner {
			
		}
		.team-btn {
			position: absolute;
			bottom: 140px;
			right: 50px;
			padding: 21px 75px;
		}

		.register-btn {
			text-align: right;
    		padding-bottom: 40px;
		}
	}
}



.content-playoffs-logo {
	padding: 50px 30px 30px 0;
	max-width: 239px;
	text-align:center;
	margin:auto;
	img {
		max-width: 100%;
	}
	@include breakpoint(sm) {
		text-align:none;
	}
}



// Exercise


.exercise {
	position: relative;
	@include clearfix();
	overflow: hidden;
	.exercise-content {
		background-color: #f8f8fa;
		margin-bottom: 85px;
	}
	.slick-list {
		overflow: visible;
	}
	.video-player {
		width: 85%;
		height: 280px;
		margin: 0 auto 50px auto;
		display: block;
		box-shadow: 0 0 40px rgba(#000000, 0.3);
	}
	.slide-num {
		position: absolute;
		top: -10px;
		right: 20px;
		font-size: 200px;
		line-height: 1;
		color: #f0f0f2;
		font-family: $headings-font-family;
		font-weight: bold;
		letter-spacing: -30px;
		z-index: -1;
	}
	@include breakpoint(sm) {
		.video-player {
			width: 500px;
		}
		.slide-num {
			top: 10px;
			right: 70px;
			font-size: 300px;
		}
	}
	@include breakpoint(md) {
		.video-player {
			width: 500px;
			height: 340px;
			float: left;
			margin-top: 40px;
			margin-left: 50px;
			margin-bottom: -130px;
		}
		.event-block {
			.event-content {
				padding-top: 50px;
			}
		}
	}
	@include breakpoint(lg) {
		.video-player {
			width: 600px;
			margin-top: -200px;
			margin-left: 140px;
			margin-bottom: -130px;
		}
	}
}




// FAQ

.faq-content {
	margin-bottom: 50px;
	@include breakpoint(sm) {
		max-width: 420px;
	}
}

//footer
.footer-register-event-button {
	font-size: 14px !important;
	@include breakpoint(sm) {
		font-size: 19px !important;
	}
}


.slider.only-one-event {
	.slick-track {
		width: 100% !important;
    	a { 
			width: 50% !important;
    	}
	}
}
