//---------------------------------------------------------//
// TYPOGRAPHY
//---------------------------------------------------------// 
// - All types of typography
// - Font-face
// - Headings
// - Paragraphs
//---------------------------------------------------------//

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i');

@font-face {
	font-family: 'TradeGothic';
	src: url('#{$font-url}/tradegothicltstdbold.eot');
	src: url('#{$font-url}/tradegothicltstdbold.eot?#iefix') format('embedded-opentype'),
		url('#{$font-url}/tradegothicltstdbold.svg#tradegothicltstdbold') format('svg'),
		url('#{$font-url}/tradegothicltstdbold.ttf') format('truetype'),
		url('#{$font-url}/tradegothicltstdbold.woff') format('woff'),
		url('#{$font-url}/tradegothicltstdbold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'TradeGothic';
	src: url('#{$font-url}/tradegothicltstd.eot');
	src: url('#{$font-url}/tradegothicltstd.eot?#iefix') format('embedded-opentype'),
		url('#{$font-url}/tradegothicltstd.svg#tradegothicltstd') format('svg'),
		url('#{$font-url}/tradegothicltstd.ttf') format('truetype'),
		url('#{$font-url}/tradegothicltstd.woff') format('woff'),
		url('#{$font-url}/tradegothicltstd.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}


h1, h2, h3, h4, h5, h6, p, ul, ol, .h1, .h2, .h3, .h4, .h5, .h6 {
	margin-top: 0;
}

h1, h2, h3 {
	text-transform: uppercase;
}


ul, ol {
	line-height: 1.57;
}




p, span, strong {
	&.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
		font-family: $font-family-sans-serif;
		font-weight: normal;
		line-height: 1.43;
		color: inherit;
	}
}

strong {
	&.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
		font-weight: bold;
	}
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, ul, ol {
	margin-bottom: $globalMarginBottom;
}


.text-gray {
	color: $gray !important;
}
.text-white {
	color: #ffffff;
}
.text-primary {
	color: $brand-primary !important;
}
.text-secondary {
	color: $brand-secondary !important;
}


.highlight {
	padding: 10px 15px;
	line-height: 1.2;
	display: inline-block;
	&.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
		color: #ffffff;
		font-weight: 600;
	}
	&.highlight-lg {
		padding: 5px 15px;
	}
	&.highlight-sm {
		padding: 4px 10px;
	}
	&.highlight-secondary {
		background-color: $brand-secondary;
		color: #ffffff;
	}
	&.highlight-primary {
		background-color: $brand-primary;
		color: #ffffff;
	}
}

p {
	.highlight {
		margin-bottom: 0;
	}
}

.label {
	font-size: 14px;
	font-weight: bold;
	color: $brand-secondary;
	text-transform: uppercase;
	display: block;
	margin-bottom: 15px;
}



h1, .h1 {
	font-size: 36px;
	@include breakpoint(sm) {
		font-size: 35px;
	}
	@include breakpoint(md) {
		font-size: 40px;
	}
}

h2, .h2 {
	font-size: 30px;
	@include breakpoint(sm) {
		font-size: 34px;
	}
	@include breakpoint(md) {
		font-size: 40px;
	}
}

h3, .h3 {
	font-size: 26px;
	@include breakpoint(sm) {
		font-size: 32px;
	}
}

h4, .h4 {
	font-size: 20px;
	@include breakpoint(sm) {
		font-size: 24px;
	}
}

h5, .h5 {
	font-size: 18px;
	@include breakpoint(sm) {
		font-size: 20px;
	}
}

h6, .h6 {
	font-size: 14px;
	@include breakpoint(sm) {
		font-size: 16px;
	}
}





.heading-intro {
	margin-bottom: 35px;
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		&:last-child {
			margin-bottom: 0;
		}
	}
	@include breakpoint(md) {
		margin-bottom: 50px;
	}
}


.heading-offset {
	padding-top: 13px;
}



// List

.list {
	list-style: none;
	padding: 0;
	margin: 0 0 $globalMarginBottom 0;
}