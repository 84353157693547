//---------------------------------------------------------//
// Footer
//---------------------------------------------------------// 

.footer {
	color: $brand-primary-light;
	.footer-content {
		@extend .block;
	}
	.footer-logo {
		background: url('#{$img-url}/f45-logo-dark.png') 0 0 no-repeat;
		@include background-retina(102, 50);
		display: block;
		margin: 0 auto 40px auto;
	}
	.footer-social {
		margin-bottom: 30px;
	}
	.footer-nav {
		@include clearfix();
		font-weight: 600;
		font-size: 0;
		li {
			font-size: 12px;
			display: inline-block;
			vertical-align: top;
			margin-right: 16px;
			margin-bottom: 4px;
			margin-top: 4px;
			&:last-child {
				margin-right: 0;
			}
		}
		a {
			color: $brand-primary-light;
			&:hover, &:focus {
				color: $brand-primary;
			}
		}
	}
	.footer-content-inner {
		position: relative;
	}
	
	.footer-bottom {
		background-color: $brand-primary;
		color: #ffffff;
		font-weight: 600;
		padding: 20px 15px;
		text-align: center;
		font-size: 12px;
	}
	.footer-left, .footer-right {
		text-align: center;
		margin-bottom: 15px;
		margin-left: -15px;
		margin-right: $grid-gutter-width / -2;
		margin-left: $grid-gutter-width / -2;
	}
	@include breakpoint(sm) {
		.footer-left, .footer-right {
			margin-top: 8px;
			margin-bottom: 0;
			margin-left: 0;
			margin-right: 0;
		}
		.footer-left {
			float: left;
		}
		.footer-right {
			float: right;
		}
		.footer-nav {
			li {
				font-size: $font-size-base;
			}
		}
		.footer-bottom {
			font-size: $font-size-base;
		}
	}
	@include breakpoint(md) {
		.footer-logo {
			margin-bottom: 60px;
		}
	}
	@include breakpoint(lg) {
		.footer-social {
			position: absolute;
			top:0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}


.social {
	font-size: 0;
	@extend .nav;
	&.text-center {
		li {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	li {
		font-size: 18px;
		display: inline-block;
		vertical-align: top;
		margin-right: 20px;
	}
	a {
		display: block;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		background-color: $brand-primary-light;
		color: #ffffff;
		text-align: center;
		line-height: 36px;
		&:hover, &:focus {
			background-color: $brand-primary;
		}
	}
}