//---------------------------------------------------------//
// Video 
//---------------------------------------------------------// 


@include breakpoint(lg) {
	.home {
		.video-player {
			position: relative;
			overflow: hidden;
			video, iframe {
			  	top: 40% !important;
			  	min-height: 100vw;
			  	min-width: 101vw;
			}
		}
	}
}

.events.event-events {
	overflow-x: hidden;
}


@include breakpoint(md-only) {
	.home {
		.video-player {
			video, iframe {
			  	top: 38% !important;
			}
		}
	}
}



@include breakpoint(sm-only) {
	.home {
		.video-player {
			video, iframe {
			  	top: 30% !important;
			  	min-height: 100vw;
			  	min-width: 102vw;
			}
		}
	}
}


@include breakpoint(xs-only) {
	.home {
		iframe.home-vid-iframe {
		  	top: 34% !important;
		    min-height: 100vw !important;
		    min-width: 102vw !important;
		    left: 50% !important;
		}
	}
}

.video-player {
	position: relative;
	overflow: hidden;
	video, iframe {
	   border: 0;
	   height: 100%;
	   left: 0;
	   position: absolute;
	   top: 0;
	   width: 100%;
	}
	.play-btn {
		width: 80px;
		height: 80px;
		margin-left: 0;
		&:after, &:before {
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 25px solid $brand-secondary;
			margin-left: 4px;
		}
		&.pause {
			&:before, &:after {
				width: 7px;
				height: 25px;
			}
			&:after {
				margin-left: 7px;
			}
			&:before {
				margin-left: -7px;
			}
		}
	}
	@include breakpoint(sm) {
		.play-btn {
			width: 125px;
			height: 125px;
			&:after, &:before {
				border-top: 25px solid transparent;
				border-bottom: 25px solid transparent;
				border-left: 45px solid $brand-secondary;
				margin-left: 4px;
			}
			&.pause {
				&:before, &:after {
					width: 12px;
					height: 45px;
				}
				&:after {
					margin-left: 12px;
				}
				&:before {
					margin-left: -12px;
				}
			}
		}
	}
}

.play-btn {
	width: 50px;
	height: 50px;
	background-color: #ffffff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 5;
	box-shadow: 0 0 50px rgba(#000000, 0.9);
	-webkit-transition: opacity $easing-speed $easing;
	transition: opacity $easing-speed $easing;
	&:after, &:before {
		content: '';
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 16px solid $brand-secondary;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		margin-left: 3px;
	}
	&.pause {
		&:before, &:after {
			border: none;
			width: 4px;
			height: 16px;
			background-color: $brand-secondary;
		}
		&:after {
			margin-left: 4px;
		}
		&:before {
			margin-left: -4px;
		}
	}
	&:hover, &:focus {
		opacity: 0.8;
	}
	@include breakpoint(sm) {
		width: 100px;
		height: 100px;
		margin-left: 270px;
		&:after, &:before {
			content: '';
			border-top: 18px solid transparent;
			border-bottom: 18px solid transparent;
			border-left: 32px solid $brand-secondary;
			margin-left: 6px;
		}
		&.pause {
			&:before, &:after {
				border: none;
				width: 10px;
				height: 32px;
				background-color: $brand-secondary;
			}
			&:after {
				margin-left: 10px;
			}
			&:before {
				margin-left: -10px;
			}
		}
	}
	@include breakpoint(md) {
		width: 150px;
		height: 150px;
		margin-left: 370px;
		&:after, &:before {
			content: '';
			border-top: 32px solid transparent;
			border-bottom: 32px solid transparent;
			border-left: 55px solid $brand-secondary;
			margin-left: 8px;
		}
		&.pause {
			&:before, &:after {
				width: 15px;
				height: 55px;
			}
			&:after {
				margin-left: 15px;
			}
			&:before {
				margin-left: -15px;
			}
		}
	}
	@include breakpoint(lg) {
		width: 200px;
		height: 200px;
		&:after, &:before {
			border-top: 42px solid transparent;
			border-bottom: 42px solid transparent;
			border-left: 75px solid $brand-secondary;
		}
		&.pause {
			&:before, &:after {
				width: 18px;
				height: 74px;
			}
			&:after {
				margin-left: 18px;
			}
			&:before {
				margin-left: -18px;
			}
		}
	}
}
