//---------------------------------------------------------//
// Navbar
//---------------------------------------------------------// 

.navbar {
	border-radius: 0px !important;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1011;
	color: #ffffff;
	display: table;
	width: 100%;
	height: 60px;
	-webkit-transition: all $easing-speed $easing;
	transition: all $easing-speed $easing;
	.navbar-brand {
		float: left;
		text-decoration: none !important;
		font-size: 0;
		span {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
			font-size: $font-size-base;
		}
	}
	.f45-logo {
		background: url('#{$img-url}/f45-logo.png') 0 0 no-repeat;
		@include background-retina(61, 30);
		-webkit-transition: all $easing-speed $easing;
		transition: all $easing-speed $easing;
	}
	.f45-playoffs-logo {
		background: url('#{$img-url}/f45-playoffs-logo.png') 0 0 no-repeat;
		@include background-retina(120, 14);
	}
	a {
		color: inherit;
	}
	.navbar-header, .navbar-menu-content {
		display: table-cell;
		vertical-align: middle;
	}
	.navbar-header {
		padding: 0 ($grid-gutter-width / 2);
	}
	&.navbar-fixed, &.navbar-inner {
		background-color: $brand-primary;
		height: 60px;
		position: fixed;
		.f45-logo {
			background: url('#{$img-url}/f45-logo.png') 0 0 no-repeat;
			@include background-retina(61, 30);
		}
	}
	@include breakpoint(xs-only) {
		background-color: $brand-primary;
	}
	@include breakpoint(sm) {
		height: 130px;
		.f45-playoffs-logo {
			background: url('#{$img-url}/f45-playoffs-logo.png') 0 0 no-repeat;
			@include background-retina(150, 17);
		}
		&.navbar-fixed {
			height: 70px;
		}
	}
	@include breakpoint(md) {
		.navbar-header, .navbar-menu-content {
			padding: 20px 50px;
			padding-bottom: 0px;
    		padding-top: 10px;
		}
		.f45-playoffs-logo {
			@include background-retina(209, 24);
		}
	}
	@include breakpoint(lg) {
		.f45-logo {
			background: url('#{$img-url}/f45-logo.png') 0 0 no-repeat;
			@include background-retina(102, 50);
		}
	}
}



// // Navbar Menu

// .navbar-menu-content {
// 	@include breakpoint(xs-only) {
// 		background-color: $brand-primary;
// 		overflow: auto;
// 		position: fixed;
// 		top: 0;
// 		width: $navbar-menu-width;
// 		height: 100%;
// 		right: 0;
// 		transform: translateX($navbar-menu-width);
// 		.navbar-menu-content-inner {
// 			padding: 30px;
// 		}
// 	}
// 	@include breakpoint(sm) {
// 		height: auto !important;
// 	}
// }

.navbar-menu-header {
	@include clearfix();
	margin-bottom: 0px;
	padding: 5px 30px;
	background-color: darken($brand-primary, 5%);
	color: #ffffff;
	display: table;
	width: 100%;
	height: 60px;
	overflow: hidden;
	border-bottom: 3px solid transparent;
	a {
		color: inherit;
		&:hover, &:focus {
			color: $brand-secondary;
			text-decoration: none;
		}
	}
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 0;
		color: inherit;
	}
	.navbar-menu-inner {
		display: table-cell;
		vertical-align: middle;
	}
	@include breakpoint(sm) {
		display: none;
	}
}



.navbar-menu-main {

}


.navbar-menu-btn {
	padding: 10px;
	width: 55px;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	@include breakpoint(sm) {
		display: none;
	}
}


.navbar-menu-btn .icon-line {
    width: 25px;
    height: 3px;
    border-radius: 0;
    background-color: #ffffff;
    display: block;
    position: absolute;
    left: 15px;
}

.navbar-menu-btn.active .icon-line:first-child {
    top: 29px;
    transform: rotate(45deg);
}

.navbar-menu-btn.active .icon-line:nth-child(2) {
    top: 29px;
    opacity: 0;
}

.navbar-menu-btn.active .icon-line:last-child {
    top: 29px;
    transform: rotate(-45deg);
}

.navbar-menu-btn .icon-line:first-child {
    transform: none;
    top: 23px;
    -webkit-transition: transform 0.25s ease-in-out, top 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, top 0.25s ease-in-out;
}

.navbar-menu-btn .icon-line:nth-child(2) {
    top: 29px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
}

.navbar-menu-btn .icon-line:last-child {
    top: 35px;
    
    -webkit-transition: transform 0.25s ease-in-out, top 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, top 0.25s ease-in-out;
}



.navbar-close-menu {
	background: url('#{$icon-url}/icon-close-white.png') 50% 50% no-repeat;
	background-size: 14px 14px;
	width: 34px;
	height: 34px;
	display: block;
	position: absolute;
	top: 12px;
	right: 6px;
}


.wrapper {
	z-index: 1009;
	position: relative;
	@include breakpoint(xs-only) {
		-webkit-transition: transform 0.25s cubic-bezier(1.000, 0.000, 0.000, 1.000);
		transition: transform 0.25s cubic-bezier(1.000, 0.000, 0.000, 1.000);
		&:after {
			-webkit-transition: box-shadow 0.25s cubic-bezier(1.000, 0.000, 0.000, 1.000);
			transition: box-shadow 0.25s cubic-bezier(1.000, 0.000, 0.000, 1.000);
		}
	}
	&.with-banner {
		margin-top: 50px;

		.navbar-fixed {
			top: 50px;
		}
	}
}

.live-event-bar {
	top: 0px;
	z-index: 99999;
}

.backdrop {
	background-color: rgba($gray-darker, 0.8);
	width: 100%;
	height: 100%;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999999;
	display: none;
	@include breakpoint(sm) {
		display: none !important;
	}
}

// .menu-open {
// 	@include breakpoint(xs-only) {
// 		height: 100%;
// 		overflow: hidden;
// 		width: 100%;
// 		position: fixed;
// 		.navbar-menu-content {
// 			opacity: 1;
// 			visibility: visible;
// 			transform: translateX($navbar-menu-width);
// 		}
// 		.wrapper {
// 			transform: translateX(-$navbar-menu-width);
// 			height: 100%;
// 			&:after {
// 				content: '';
// 			    position: fixed;
// 			    width: 100%;
// 			    height: 100%;
// 			    top: 0;
// 			    left: 0;
// 			    box-shadow: 0px 0 25px rgba(0,0,0,0.4);
// 			}
// 		}
// 	}
// }


@media (max-width: 767px) {
	.navbar-nav {
	    text-transform: uppercase;
	    font-size: 20px;
	    font-weight: bold;
	}
	.navbar-nav a {
	    color: #999999;
	    padding: 2px 0px 2px 16px;
	    display: block;
	    line-height: 1.5;
	    border-left: 3px solid transparent;
	}
	.navbar-nav li.active a, .navbar-nav a:hover, .navbar-nav a:focus {
	    border-color: #fb053e;
	    text-decoration: none;
	    color: #ffffff;
	}
    .navbar-menu-content {
	    background-color: rgba(0, 0, 51, 0.9);
	    overflow: auto;
	    position: fixed;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    right: 0;
	    opacity: 0;
	    visibility: hidden;
	    transition: opacity .25s ease-in-out;
	}
	.navbar-menu-content .navbar-menu-content-inner {
	    padding: 100px 25px 25px 25px;
	}
	.menu-open {
	    height: 100%;
	    overflow: hidden;
	    width: 100%;
	    position: fixed;
	}
	.menu-open .navbar-brand {
	    position: relative;
	    z-index: 10099;
	}
	.menu-open .navbar-menu-content {
	    opacity: 1;
	    visibility: visible;
	}
	.menu-open .wrapper {
	    height: 100%
	}

	.hero.home-fullscreen.main-screen {
		video {
			margin-top: 0px !important;
		}

	}

	.hero.home-fullscreen.main-screen.iframe {
		iframe {
			top: -5% !important;
			margin-top: 0px !important;
		}
	}
}

@media (max-width: 500px) {
	.hero.home-fullscreen.main-screen.iframe {
		iframe {
			top: -14% !important;
			margin-top: 0px !important;
		}
	}
}