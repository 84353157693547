//---------------------------------------------------------//
// Navigation
//---------------------------------------------------------// 
// - All Menus
//---------------------------------------------------------//


.nav {
	list-style: none;
	padding: 0;
	margin: 0;
	line-height: $line-height-base;
	li {
		margin-bottom: 0;
	}
	a:hover, a:hover {
		text-decoration: none;
	}
}




// Navbar Nav

.navbar-nav {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
	li {
		display: block;
		margin-bottom: 8px;
	}
	a {
		color: #ffffff;
		padding: 5px 0px 10px 0px;
		display: block;
		line-height: 1.5;
	}
	li.active a, a:hover, a:focus {
		border-color: $brand-secondary;
		text-decoration: none;
	}
	@include breakpoint(sm) {
		float: right;
		a {
			height: 70px;
			display: table-cell;
			vertical-align: middle;
			padding: 5px 20px 0px 20px;
			border-bottom: 5px solid transparent;
		}
		li {
			float: left;
			margin-left: 0px;
			margin-bottom: 0;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	@include breakpoint(md) {
		font-size: 18px;
		margin-right: -20px;
		a {
			height: 70px;
			display: table-cell;
			vertical-align: middle;
		}
		li {
			margin-left: 20px;
		}
	}
	@include breakpoint(lg) {
		li {
			margin-left: 30px;
		}
	}
}



